<template>
    <div class="w-full px-4 hide-scrollbar" style="background-color:#F7F5F9; overflow-y:scroll">
        <bh-loading :show="loading" />
        <CustomFieldDrawer />
        <ElevationDrawer />
        <a-modal :visible="previewModal.visible" @cancel="closeModal" :footer="null" centered>
            <div class="mt-3">
                <img style="height:100%; width:100%" :src="previewModal.item" />
            </div>
        </a-modal>
        <a-modal :confirm-loading="elevationLinkDialog.processing" :visible="elevationLinkDialog.show" @cancel="elevationLinkDialog.show = false" centered @ok="linkElevation">
            <a-form>
                <a-form-item class=mb-0>
                    <div slot='label'>
                        Link <strong>{{elevationLinkDialog.from.name}}</strong> to:
                    </div>
                    <small class="text-med-gray block text-left mb-1" style="line-height:1;">This is mainly used to connect attached Semis/Towns elevations together.</small>
                    <a-select v-model="elevationLinkDialog.to" mode="multiple" style="width: 100%" >
                        <a-select-option v-for="unit in elevations.filter(elev => elev.id !== elevationLinkDialog.from.id)" :value="unit.id" :key="unit.id">
                            {{unit.name}}
                        </a-select-option>
                    </a-select>

                </a-form-item>
            </a-form>
        </a-modal>
        <a-row :gutter="16" style="overflow-y:scroll; flex-direction: column;" class="hide-scrollbar dF h-full">
            <div class="dF w-full">
                <a-col :span="18">
                    <a-form-model :model="newModel" ref="newModel">
                        <a-tabs default-active-key="1" @change="tabName" size="large">
                            <a-tab-pane key="1" tab="Home Model Details">
                                <div class="dF">
                                    <a-icon @click="backButton" class="mr-4 mt-2" style="font-size:1.75rem; cursor:pointer" type="arrow-left" />
                                    <h2>
                                        <span >Back to {{currentModelCRUMBS}}</span>
										<br/>
										<h5 style="display: inline-block;" v-if="newModel.name">{{ newModel.name }}</h5>
                                    </h2>
                                </div>

                                <div style="width:100%; background-color:white; float:left;">
                                    <div>
                                        <p class="mt-3 ml-3" style="font-size:15px"><strong>Model Information</strong></p><hr>
                                    </div>



                                    <a-row>
                                        <a-col :span="11" class="ml-3">
                                        <a-form-model-item label="Model Marketing Name" required prop="name" :rules="req('Marketing Name is required')">
                                            <a-input v-model.lazy="newModel.name" placeholder="The Valencia, etc...." />
                                        </a-form-model-item>
                                        </a-col>
                                        <a-col :span="11" style="float:right" class="mr-3">
                                        <a-form-model-item label="Model Code" prop="code">
                                            <a-input  v-model.trim="newModel.code" placeholder="Example: 40-1" >
                                            </a-input>
                                        </a-form-model-item>
                                        </a-col>
                                    </a-row>
                                    <a-row>
                                        <a-col :span="11" class="ml-3">
                                        <a-form-model-item :min="1" label="Frontage (Only digits)"  required prop="size" :rules="req('Frontage is required')">
                                            <a-input type="number" v-model="newModel.size" style="width:100%" placeholder="Example value: 40"/>
                                        </a-form-model-item>
                                        </a-col>
                                        <a-col :span="11" class="mr-3" style="float:right">
                                        <a-form-model-item label="Model Type"  required prop="type" :rules="req('Model Type is required')">
                                            <a-auto-complete
                                                v-model="newModel.type"
                                                :data-source="dataSource"
                                                style="width: 100%"
                                                placeholder="Singles, towns, contemporary lane tomes. etc…"
                                                @search="onSearch"

                                            />

                                        </a-form-model-item>
                                        </a-col>
                                    </a-row>
									<a-row>
                                        <a-col :span="24" class="ml-3">
											<a-form-model-item prop="isStandingInventory">
												<span slot="label">
													Standing Inventory Model
													<a-tooltip overlayClassName="change-tooltip-color" title="Enabling this setting designates the model as a standing inventory model home, which indicates that it is a pre-built property ready for immediate occupancy and available for sale">
														<a-icon type="question-circle-o" style="color: var(--orange);" />
													</a-tooltip>
												</span>
												<a-switch v-model="newModel.isStandingInventory" />
											</a-form-model-item>
                                        </a-col>
                                    </a-row>

                                </div>

                                <!-- <div style="width:27%; background-color:white; float:right;">
                                    <a-col>
                                        <p class="mt-3 ml-3">Status and Visibility</p><hr>
                                    </a-col>
                                    <a-col>
                                        <a-form-model-item class="ml-3">
                                            <a-select v-model="newModel.status" style="width:95%">
                                                <a-select-option v-for="stat in status" :key="stat.value">{{stat.label}}</a-select-option>
                                            </a-select>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-button @click="submit" style="float:right; width:100px" class="mr-3 mb-3 mt-3" type="primary">{{!this.$route.params.id ? 'Save' : 'Update'}}</a-button>
                                    <h6 @click="deletePopup" v-if="this.$route.params.id != null" class="ml-3 mb-3 mt-4" style="float:left; color:var(--orange); text-decoration:underline">DELETE HOME MODEL</h6>
                                </div>

                                <div style="width:27%; height:288px; background-color:white; float:right" class="mt-5">
                                    <p class="mt-3 ml-3">Featured Image</p><hr>
                                        <a-form-model-item class="image-box">
                                            <ImageBox :img="newModel.image" @callback="updateImage"></ImageBox>
                                        </a-form-model-item>
                                </div> -->

                                <div style="width:100%; float:left; background-color:white;" class="mt-5">

                                        <p class="mt-3 ml-3" style="font-size:15px"><strong>Elevation Summary</strong></p><hr>
                                        <div  v-if="elevations.length">
                                            <a-table :rowKey="(e) => e.id" style="overflow-x:scroll" :columns="columns" :data-source="elevations" class="white-table mb-3 hide-scrollbar" :pagination="false">
                                                <div slot="thumbnail" slot-scope="obj" class="relative">
                                                    <a-tooltip overlayClassName="change-tooltip-color" placement="topLeft" :title="`Connected to ${connections[obj.id].join(',')}`"  v-if="connections[obj.id]" >
                                                        <div style="position:absolute; bottom:0; right:0; width:25px; height:25px; background:var(--primary); color:white;line-height:1; border-radius:100%; display:flex; justify-content:center; align-items:center; transform:translate3d(50%,50%,0)">
                                                            <a-icon type="link" />
                                                        </div>
                                                    </a-tooltip>
                                                    <img style="height:75px; width:120px; object-fit:cover" :src="obj.image" />
                                                </div>
                                                <div slot="price" slot-scope="obj" class="relative">
                                                    {{getPrice(obj)}}
                                                </div>
                                                <div slot="area" slot-scope="obj">{{obj.packages[0].sqft}} {{ obj.packages[0].sqft2 && obj.packages[0].sqft2 > 0 ? `+ ${obj.packages[0].sqft2}` : ``}}</div>
                                                <div slot="feature" slot-scope="obj">
                                                    <div>{{`${obj.packages[0].beds || 0} Beds`}}</div>
													<div style="white-space: nowrap;">{{`${obj.packages[0].dens || 0} Den/Library`}}</div>
                                                    <div>{{`${obj.packages[0].baths || 0} Baths`}}</div>
													<div>{{`${obj.packages[0].powderRooms || 0} Pwd`}}</div>
                                                    <div style="white-space: nowrap;">{{`${obj.packages[0].garages || 0} Car Garages`}}</div>
                                                    <div>{{`${obj.packages[0].stories || 0} Stories`}}</div>
                                                </div>
                                                <div slot="option" slot-scope="obj">
                                                    <div v-for="pack in obj.packages" :key="pack.id">{{pack.name}}</div>
                                                    <!-- {{obj.packages[0].name == 'Standard Floor Plan' ||'Optional Floor Plan' ? obj.packages[0].name.substring(0,8):obj.packages[0].name}} -->
                                                </div>
                                                <div style="color:#9EA0A5" slot="created" slot-scope="obj">{{getDate(obj.createdAt)}}</div>
                                                <div style="color:#9EA0A5" slot="updated" slot-scope="obj">{{getDate(obj.updatedAt)}}</div>
                                                <div slot="action" slot-scope="obj">
                                                    <a-popover overlayClassName="no-padding" trigger="focus" >
                                                        <div slot="content">
                                                            <div style="cursor:pointer" @click="editElevation(obj)" class="option dF aC p-2">
                                                                <div class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></div>
                                                                <div>Edit Elevation</div>
                                                            </div>
                                                            <div style="cursor:pointer" @click="openLinkDialog(obj)" class="option dF aC p-2" v-if="elevations.find(elev => elev.id !== obj.id)">
                                                                <div class="mr-3">
                                                                    <a-icon type="link" />
                                                                </div>
                                                                <div>Link Elevations</div>
                                                            </div>
                                                            <div style="cursor:pointer" @click="duplicateElevation(obj)" class="option dF aC p-2">
                                                                <div class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg></div>
                                                                <div>Duplicate Elevation</div>
                                                            </div>
                                                            <div style="cursor:pointer" @click="removeElevation(obj)" class="option dF aC p-2">
                                                                <div class="mr-3" style="color:#FD647C"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></div>
                                                                <div>Remove Elevation</div>
                                                            </div>
                                                        </div>
                                                        <div class="relative more-option dF aC" tabindex="0">
                                                            <div class="absolute circle" style="background-color:#fff; width:42px; height:42px; border-radius:50%; left:-15px"></div>
                                                            <a-icon class="more-icon" style="z-index:10" type="more" />
                                                        </div>
                                                    </a-popover>
                                                </div>
                                            </a-table>

                                            <a-row :gutter="16">
                                                <a-col>
                                                    <a-button @click="addElevation" class="ml-3 mb-3" style="background-color:#ECE9F1; color:#3F3356;" size="large"><a-icon type="plus" />ADD NEW ELEVATION</a-button>
                                                </a-col>
                                            </a-row>
                                        </div>
                                        <a-row v-else>
                                            <a-col><a-button @click="addElevation" class="ml-3 mb-3" style="background-color:#ECE9F1; color:#3F3356;" size="large"><a-icon type="plus" />ADD NEW ELEVATION</a-button></a-col>
                                        </a-row>


                                </div>
                            </a-tab-pane>
                            <a-tab-pane key="2" tab="Media">
                                <div style="width:100%; background-color:white; float:left">
                                <GalleryImage :galleryImages="newModel.media.mediaImage" @previewGallery="previewGallery" @galleryImages="galleryImg" :title="'Gallery Image'" />
                                <!-- <div>
                                    <p class="mt-3 ml-3" style="font-size:15px"><strong>Gallery Images</strong></p><hr>
                                </div>
                                <a-row>
                                    <h6 class="ml-3">{{(newModel.media && newModel.media.mediaImage) ? newModel.media.mediaImage.length : 0}}/48 Files</h6>
                                    <div style="display:flex; width:100%; overflow-x:scroll; padding-top:15px" class="hide-scrollbar pl-3 pr-3" v-if="newModel.media && newModel.media.mediaImage.length">
                                        <div v-for="(image, imageI) in newModel.media.mediaImage" :key="imageI">
                                            <div class="mr-4" style="height:120px; width:80px;">
                                                <div class="relative previewImage" style="height:100%; width:100%">
                                                    <div @click="deleteMedia(imageI)" class="absolute" style="right:-12px; top: -13px; z-index:10">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.023" height="26.023" viewBox="0 0 26.023 26.023"> <g id="Group_4116" data-name="Group 4116" transform="translate(-733.487 -1405.414)"> <ellipse id="Ellipse_15" data-name="Ellipse 15" class="cls-1" fill="#fff" cx="10.5" cy="10" rx="10.5" ry="10" transform="translate(736 1408)"/> <g id="Group_4115" data-name="Group 4115" transform="translate(-28 1)"> <path id="Icon_awesome-times-circle" data-name="Icon awesome-times-circle" class="cls-2" fill="#fd647c" d="M13.574.563A13.012,13.012,0,1,0,26.586,13.574,13.009,13.009,0,0,0,13.574.563Zm6.38,16.427a.63.63,0,0,1,0,.892l-2.078,2.072a.63.63,0,0,1-.892,0l-3.41-3.442-3.416,3.442a.63.63,0,0,1-.892,0L7.194,17.876a.63.63,0,0,1,0-.892l3.442-3.41L7.194,10.159a.63.63,0,0,1,0-.892L9.272,7.189a.63.63,0,0,1,.892,0l3.41,3.447L16.99,7.194a.63.63,0,0,1,.892,0l2.078,2.078a.63.63,0,0,1,0,.892l-3.447,3.41Z" transform="translate(760.925 1403.852)"/> </g> </g> </svg>
                                                    </div>
                                                    <div @click="previewImage(image)" class="absolute zoomButton" style="height:100%; width:100%; background-color:rgba(64, 69, 76, 0.5)">
                                                        <div class="w-full dF aC jC h-full">
                                                            <i style="color:white; font-size:20px" class="fe fe-search" />
                                                        </div>
                                                    </div>
                                                    <img style="width:100%; height:100%; object-fit:cover;" :src="image.url" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a-button :disabled="(newModel.media && newModel.media.mediaImage)? newModel.media.mediaImage.length > 48 : false " @click="$store.commit('MEDIA_SELECT',{callback:selectedMediaImage,type:'images'})" style="background-color: var(--light-gray)" size="large" class="ml-3 mt-3"><a-icon type="plus" />ADD IMAGES</a-button>
                                    <small><p class="ml-3">Images should have 830px by 460px fo thumbnails on bottom. Bigger size images will be cropped automatically. Minimum 2 images are required to display gallery.</p></small>
                                </a-row> -->
                            </div>

                            <!-- <div style="width:27%; background-color:white; float:right;">
                                <a-col>
                                    <p class="mt-3 ml-3">Status and Visibility</p><hr>
                                </a-col>
                                <a-col>
                                    <a-form-model-item class="ml-3">
                                        <a-select v-model="newModel.status" style="width:95%">
                                            <a-select-option v-for="stat in status" :key="stat.value">{{stat.label}}</a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </a-col>
                                <a-button @click="submit" style="float:right; width:100px" class="mr-3 mb-3 mt-3" type="primary">{{!this.$route.params.id ? 'Save' : 'Update'}}</a-button>
                                <h6 @click="deletePopup" v-if="this.$route.params.id != null" class="ml-3 mb-3 mt-4" style="float:left; color:var(--orange); text-decoration:underline">DELETE HOME MODEL</h6>
                            </div> -->

                            <div style="width:100%; float:left; background-color:white" class="mt-5">
                                <div>
                                    <p class="mt-3 ml-3" style="font-size:15px"><strong>Video</strong></p><hr style="margin-bottom:0px">
                                </div>
                                <a-row :gutter="16" class="p-3">
                                <a-col :span="24">
                                    <a-form-model-item style="width:100%" label="Model Home Video URL">
                                        <a-input v-model="newModel.media.mediaVideo">
                                        </a-input>
                                    </a-form-model-item>
                                </a-col>
                                <a-col :span="24">
                                    <a-form-model-item style="width:100%; margin-bottom:0px !important" label="360 Virtual Tour">
                                        <a-input v-model="newModel.media.media360">
                                        </a-input>
                                        <small>Provide iframe embed code for the 360 virtual tour</small>
                                    </a-form-model-item>
                                </a-col>
                                </a-row>
                            </div>
                            <!-- <div class="mt-5" style='float:right; background-color:white; width:27%; height:288px;'>
                                <p class="mt-3 ml-3">Featured Image</p><hr>
                                <div>
                                    <a-form-model-item class="image-box">
                                        <ImageBox :img="newModel.image" @callback="updateImage"></ImageBox>
                                    </a-form-model-item>
                                </div>
                            </div> -->
                            </a-tab-pane>
                        </a-tabs>
                    </a-form-model>
                </a-col>
                <a-col :span="6" class="testtt" :style="openTab === '1'? 'margin-top:155px':'margin-top:71px'" style="position:sticky">
                    <div style="width:100%; background-color:white; float:right;">
                        <div>
                            <p class="mt-3 ml-3" style="font-size:15px"><strong>Status and Visibility</strong></p><hr style="margin-bottom:0px">
                        </div>
                        <div class="p-3">
                            <a-form-model-item>
                                <a-tooltip overlayClassName="change-tooltip-color" :title="$p < 40 ? 'You do not have access to change status' : null">
                                    <a-select v-model="newModel.status" style="width:100%" :disabled="$p < 40">
                                        <a-select-option v-for="stat in status" :key="stat.value">{{stat.label}}</a-select-option>
                                    </a-select>
                                </a-tooltip>
                            </a-form-model-item>
                            <div class="dF aC" style="justify-content:space-between">
                                <h6 class="mr-3" @click="deletePopup" style="float:left; color:var(--orange); text-decoration:underline; cursor:pointer; margin-bottom:0px">DELETE</h6>
                                <a-button size="large" :class="!$route.params.id? '' : 'ml-3'" @click="submit" type="primary">{{!this.$route.params.id ? 'SAVE' : 'UPDATE'}}</a-button>
                            </div>
                        </div>
                    </div>

                    <div style="width:100%; height:288px; background-color:white; float:right" class="mt-5">
                        <p class="mt-3 ml-3" style="font-size:15px"><strong>Featured Image</strong></p><hr>
                            <div class="dF aC jC" style="width: 100%;">
                                <div style="padding: 0; width:90%;">
                                    <ImageBox :img="newModel.image" @callback="updateImage" @formats="formats"></ImageBox>
                                </div>
                            </div>
                    </div>
                </a-col>
            </div>
            <!-- <div class="footer--app" style="z-index:5; width:100%; padding:20px 1.5em; display: flex; flex-grow: 1;">
                <AppFooter />
            </div> -->
        </a-row>
    </div>
</template>

<script>
// window.onbeforeunload = function() {
//     console.log('changinggg')
//     this.$store.commit('OPEN_ALERT')
// }
    import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
    import ElevationDrawer from '@/components/models/ElevationDrawer'
    import ImageBox from '@/components/models/ImageBox'
    import GalleryImage from '@/components/models/GalleryImage'
    import {markerIcon} from 'bh-mod'
    import bhLoading from 'bh-mod/components/common/Loading'
    import CustomFieldDrawer from '@/components/models/CustomFieldDrawer'
    import AppFooter from 'bh-mod/components/common/AppFooter'
    export default {
        components: { ImageBoxSelector, ElevationDrawer,ImageBox, GalleryImage,bhLoading,AppFooter,CustomFieldDrawer},
        data() {
            return {
                refresh:0,
                create:false,
				change: false,
				updateChecker: false,
                delete:false,
                elevationLinkDialog:{
                    show:false,
                    from:{},
                    processing:false,
                    to:[],
                },
                loading:false,
                previewModal:{
                    visible:false,
                    item:''
                },
                delModel:false,
                openTab:'1',
                status:[
                    {label:'Published', value:'published'},
                    {label:'Draft', value:'draft'},
                ],
                editPage:false,
                dataSource: [],
                dataSourceSize: [],
                newTime: 'SDFXCV####XDVXCVX',
                newModel:{
                    name:'',
                    status:'draft',
                    buildingType:'models',
                    code:'',
                    size:'',
                    type:'',
                    image:'',
					isStandingInventory:false,
                    media:{
                        mediaImage: [],
                        mediaVideo:'',
                        media360:'',
                    }
                },
                disable:false,
                visible:false,
                modalTitle: 'Elevation',
                deleteObj: {},
                buttonTitle: '',
                columns:[
                    {
                        key:"thumbnail",
                        // slots:{title:"Thumbnail"},
                        title:'Thumbnail',
                        scopedSlots:{customRender:'thumbnail'}
                    },
                    {
                        key:"price",
                        // slots:{title:"Thumbnail"},
                        title:'Price',
                        scopedSlots:{customRender:'price'}
                    },
                    {
                        dataIndex:"name",
                        key:"name",
                        title:'Elevation Type'
                    },
                    {
                        title:'Area Size',
                        key:'area',
                        slots:{title:'Area Size'},
                        scopedSlots:{customRender:'area'}
                    },
                    {
                        title:'Feature',
                        key:'feature',
                        slots:{title:'Feature'},
                        scopedSlots:{customRender:'feature'}
                    },
                    {
                        title:'Floor Plan Option',
                        key:'option',
                        slots:{title:'Floor Plan Option'},
                        scopedSlots:{customRender:'option'}
                    },
                    {
                        title:'Date Created',
                        key:'created',
                        slots:{title:'Date Created'},
                        scopedSlots:{customRender:'created'}
                    },
                    {
                        title:'Date Updated',
                        key:'updated',
                        slots:{title:'Date Updated'},
                        scopedSlots:{customRender:'updated'}
                    },
                    {
                        key:'action',
                        scopedSlots:{customRender:'action'}
                    }
                ]
            }
        },
        watch:{
            updatedKey(val){
                console.log('UPDATING ELEVATIONS', val),
                this.refresh = Date.now()
            },
            '$route':{
                immediate:true,
                handler(val){
                    console.log('CHANGEDDDDDDD', val)
                    if (val.path.includes('modeldetails')) {
                        this.$store.commit('changeType', '')
                    }
                    if (val.path.includes('modeldetails') && val.params && val.params.id) {
                        let crumbs = this.$store.state.models.crumbs
                        if (crumbs.length == 0) {
							console.log('CRUMBS', crumbs)
							crumbs.push('All Home Models')
							crumbs.push(this.unitGroups[val.params.id] && this.unitGroups[val.params.id].name)
                            this.$store.commit('SET_CRUMBS', crumbs)
                        }
                    }
                    this.editPageStart()
                }
            },
            dialog:{
                handler(val) {
                    this.newTime = Date.now() + ''
                }
            },
            currentModel:{
                deep:true,
                handler(val){
					console.log('NEW MODEL', this.newModel)
                    if (!this.$route.params.id || !val) return null
                    console.log('***********************************',{currentModel:val, storeUnitGroups:this.$store.state.models.unitGroups, paramid:this.$route.params.id})
                    let obj = JSON.parse(JSON.stringify(this.$store.state.models.unitGroups[this.$route.params.id]))
					if (!obj.hasOwnProperty('isStandingInventory')) obj.isStandingInventory = false
					let units = obj.units
                    this.newModel.units = units
                }
            },
			newModel:{
				handler(val) {
					if (this.updateChecker == false){
						this.updateChecker = true
					} else {
						this.change = true
					}
				},
				deep:true
			},
            openTab(val) {
                console.log('changgggeeeeeee',val)
                this.openTab = val
            }
        },
        computed:{
			dateFormat(){
				return this.$store.state.models.allSettings.user && this.$store.state.models.allSettings.user.options && this.$store.state.models.allSettings.user.options.regional && this.$store.state.models.allSettings.user.options.regional.dateFormat ? this.$store.state.models.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
			},
            updatedKey(){
                return this.$store.state.models.updatedKey
            },
            deleteModelType() {
                return this.$store.state.models.deleteModelType
            },
            connections(){
                let connections = {}
                this.elevations.forEach(elev => {
                    if (elev.connected && elev.connected.length){
                        connections[elev.id] = elev.connected.map(cn => {
                            let id = cn.id ? cn.id : cn
                            return this.$store.state.models.units[id].name
                        })
                    }
                })
                return connections
            },
            unitGroups(){
                return this.$store.state.models.unitGroups
            },
            currentModel(){
                return this.$store.state.models.unitGroups[this.$route.params.id]
            },
            instance(){
                return this.$store.state.instance
            },
            appData(){
                return this.$store.state.appData
            },
            getTitle(){
                return `Create a new `
            },
            unitTypes(){
            let types = this.$store.state.models.unitTypes
            let result = {}
            types.forEach(x => {
                let name = x.name.trim().toLowerCase()
                if (!result[name]){
                result[name] = {
                    label:x.name,
                    value:name,
                    children:[]
                }
                }
                result[name].children.push({label:x.size,value:x.id})
            })

            return Object.values(result)
            },
            elevations() {
                let refresh = this.refresh
                // let elevations = []
                if (!this.$route.params.id) return []
                console.log('this.$store.state.models.units',this.$store.state.models.units)
                return Object.values(this.$store.state.models.units).filter(x => x.unitGroup).filter(x => x.unitGroup.id ?  x.unitGroup.id === this.$route.params.id : x.unitGroup === this.$route.params.id )
                // else return this.$store.state.models.unitGroups[this.$route.params.id].units || []

            },
			currentModelCRUMBS(){
				return this.$store.state.models.crumbs && this.$store.state.models.crumbs[0] || "All Home Models";
			}
        },
        methods:{
            getPrice(obj){
                let prices = []
                if (obj.packages.length){
                    obj.packages.forEach(x => {
                        if (!prices.includes(x.price)){
                            prices.push(x.price);
                        }
                    })
                }
                prices = prices.filter(x => x != null).sort((a,b) => a-b)
				if (prices.length == 1){
					return '$' + prices[0].toLocaleString()
				} else if (prices.length > 1){
					return '$' + prices[0].toLocaleString() + ' - ' + '$' + prices[prices.length-1].toLocaleString()
				} else {
					return '$0'
				}
            },
            formats(e) {
                if (e != undefined) {
                    if (!this.newModel.media) {
                        this.newModel.media = {
                            formats:e
                        }
                    } else if (!this.newModel.media.formats) {
                        this.newModel.media.formats = e
                    } else if (this.newModel.media && this.newModel.media.formats) this.newModel.media.formats = e
                }
            },
            backButton() {
                if (this.$store.state.models.crumbs.length > 1) {
                    let crumbs = this.$store.state.models.crumbs
                    if (crumbs[0] == 'All Home Models') {
                        this.$router.push(`/showmodel`)
                        // this.$store.commit('SET_CRUMBS', ['All Home Models'])
                    } else {
                        this.$router.push(`/showmodel/${crumbs[0]}`)
                        // this.$store.commit('SET_CRUMBS', [crumbs[0]])
                    }
                } else {
                    this.$router.push(`/showmodel`)
                    // this.$store.commit('SET_CRUMBS', ['All Home Models'])
                }
            },
            getDate(date) {
                if (date != '' || date != null) {
                    let year = date.substring(0,4)
					let month = date.substring(5,7)
					let day = date.substring(8,10)
					if (this.dateFormat == 'YYYY/MM/DD') return `${year}/${month}/${day}`
					else if (this.dateFormat == 'DD/MM/YYYY') return `${day}/${month}/${year}`
					else {
						return `${month}/${day}/${year}`
					}
                } else {return ''}
            },
            galleryImg(data){
                console.log('daataaaa',data)
            },
            closeModal(){
                this.previewModal.visible = false
            },
            previewGallery(item) {
                this.previewModal.visible = true
                this.previewModal.item = item.url
            },
            deleteMedia(index) {
                console.log('deleteinggg', index)
                this.newModel.media.mediaImage.splice(index,1)
            },
            tabName(e){
                this.openTab = e
            },
            req:msg=>({required:true,message:msg}),
            showEdit(ele) {
                console.log('showEdit', ele)
                this.$store.commit('SHOW_EDIT_ELEVATION',{ele,callback:this.updateElevations})
            },
            selectedMediaImage(item) {
                let url = {
                    url:"",
                    id:""
                }
                url.url = item.url
                url.id = item.id
                if (!this.newModel.media) {
                    console.log('aaaaaa')
                    this.newModel.media = {
                        mediaImage:[],
                        mediaVideo:'',
                        media360:'',
                    }
                    this.newModel.media.mediaImage.push(url)
                    this.$forceUpdate()
                }
                else {
                    this.newModel.media.mediaImage.push(url)
                    this.$forceUpdate()
                }
                console.log('aaaaa', this.newModel.media)
                // if (!this.newModel.media && !this.newModel.media.mediaImage) {
                //     this.newModel.media = {}
                //     this.newModel.media.mediaImage = []
                //     this.newModel.media.mediaImage.push(url)
                // }
                // else {
                //     this.newModel.media.mediaImage.push(url)
                // }

                // console.log('mediaImage', this.newModel.media.mediaImage)
            },
            updateImage(data) {
                this.newModel.image = data
            },
            deleteModel() {
                console.log('deleeee', this.newModel.id)
                console.log("deell", this.delModel)
                this.delModel = false
                this.$api.delete(`/units/${this.instance.id}/unitgroups/${this.newModel.id}`).then(({data}) => {
                    this.$store.dispatch('DELETE_MODEL', data.id)
                    this.delete = true
                    this.$router.push(`/showmodel`)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            },
            deletePopup() {

                if (this.$p < 40) return this.$message.error('You do not have permission to delete this model')

                let self = this

                this.$confirm({
                    title: "Delete",
                    content: h => <div>Do you want to delete Model. Deleting will permanently remove the Model</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.deleteModel()
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            },
            updateElevations(e){
                this.elevations = this.elevations.map(x => {
                    if (x.id === e.id) return e
                    return x
                })
            },
            editElevation(ele) {
                if (this.$p < 20 && this.currentModel.status !== 'draft') return this.$message.error('You do not have permission to edit this elevation')
                this.$store.commit('SHOW_EDIT_ELEVATION',{ele,callback:this.updateElevations})
            },
            openLinkDialog(elev){
                if (this.$p < 20 && this.currentModel.status !== 'draft') return this.$message.error('You do not have permission to link elevations in this Model')
                let to = JSON.parse(JSON.stringify(elev.connected || []))
                to = to.map(x => x.id ? x.id : x)
                this.elevationLinkDialog = {
                    show:true,
                    from:elev,
                    processing:false,
                    to
                }
            },
            async linkElevation(){
                if (this.$p < 20 && this.currentModel.status !== 'draft') return this.$message.error('You do not have permission to edit this model')
                let {from,to} = this.elevationLinkDialog

                console.log('FROM AND TO', from, to)
                let linkElevations = to.map(x => x = this.elevations.find(y => y.id == x).name)
                let doesntContain = false
                linkElevations.forEach(x => {
                    if (!x.split("").includes(from.name[0])){
                        doesntContain = true
                    }
                })

                if (doesntContain){
                    let self = this
                    this.$confirm({
                        title: "Different Elevations",
                        content: h => <div>Are you sure you want to select two different elevations as a link?</div>,
                        okText: 'OK',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        centered: true,
                        onOk() {
                            self.confirmLinkElevation()
                        },
                        onCancel() {
                            console.log('Cancel')
                        }
                    })
                } else {
                    this.confirmLinkElevation()
                }
            },
            async confirmLinkElevation(){
                let {from,to} = this.elevationLinkDialog
                let ids = [from.id,...to]
                this.elevationLinkDialog.processing = true

                let linksToRemove = this.elevations.filter(elev => {
                    if (ids.includes(elev.id)) return false
                    let result = false
                    if (elev.connected && elev.connected.length){
                        elev.connected.forEach(connected => {
                            if (result) return
                            let id = connected.id ? connected.id : connected
                            if (ids.includes(id)) return result = true
                        })
                    }
                    return result
                })

                for (const key in linksToRemove) {
                    if (Object.hasOwnProperty.call(linksToRemove, key)) {
                        const elevToDisconnect = linksToRemove[key];
                        let connected = elevToDisconnect.connected.filter(con => {
                            let id = con.id ? con.id : con
                            return !ids.includes(id)
                        })
						try {
							await this.$api.put(`/units/:instance/${elevToDisconnect.id}`, { connected })
							this.$store.commit('SET_PROP', {
								where: ['units', elevToDisconnect.id, 'connected'],
								what: connected,
							})
						} catch (err) {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						}
                    }
                }

                for (const key in ids) {
                    if (Object.hasOwnProperty.call(ids, key)) {
                        const id = ids[key];
                        const idsToConnect = ids.filter(x => x!==id)
						try {
							await this.$api.put(`/units/:instance/${id}`, { connected: idsToConnect })
							this.$store.commit('SET_PROP', {
								where: ['units', id, 'connected'],
								what: idsToConnect,
							})
						} catch (err) {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						}
                    }
                }

                this.elevationLinkDialog = {
                    show:false,
                    processing:false,
                    from:{},
                    to:[]
                }
            },
            duplicateElevation({id,_id,...ele}){

                if (this.$p < 20 && this.currentModel.status !== 'draft') return this.$message.error('You do not have permission to edit this model')

                ele.packages = ele.packages.map( ({id,_id,...pkg}) => {
                    pkg.floors = pkg.floors.map(({id,_id,...floor}) => floor)
                    return pkg
                })

                ele.customFields = ele.customFields.map( ({id, _id, ...fields}) => {
                    return fields
                })

                this.loading = true
                this.$api.post(`/units/${this.instance.id}`, ele).then( ({data}) => {
                    console.log('data', data)
					this.newModel.units.push(data)
                    this.$store.dispatch('DUPLICATE_ELEVATION', data)
                    this.loading = false
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            },
            removeElevation(ele) {

                if (this.$p < 40) return this.$message.error('You do not have permission to delete this elevation')

                console.log('idd', ele.id)
                this.deleteObj = ele
                let self = this
                this.$confirm({
                    title: "Delete",
                    content: h => <div>Do you want to delete Elevation. Deleting will permanently remove the Elevation</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.confirmDelete()
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })

            },
            confirmDelete() {
                console.log('deleteee', this.deleteObj.id)
                this.loading = true
                this.$api.delete(`/units/${this.instance.id}/${this.deleteObj.id}`).then(({data}) => {
                    console.log('data come back', data)
					let index = this.newModel.units.findIndex(x => x.id == data.id)
					this.newModel.units.splice(index, 1)
                    this.$store.dispatch('DELETE_ELEV', data.id)
                    this.loading = false
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
                // let index = this.elevations.findIndex(x => x.id == this.deleteObj.id)
                // this.elevations.splice(index,1)
                // this.visible = false
            },
            submit() {
                if (!this.$route.params.id) {
                    return this.createModel()
                } else {
                    return this.updateModel()
                }
            },
            updateModel() {
                if (this.$p < 20 && this.currentModel.status !== 'draft') {
                    return this.$message.error('You do not have permission to update this model')
                }
				this.$refs.newModel.validate(validate => {
					if (validate){
						this.trimModel()
						console.log('newmodel', this.newModel)
						this.loading = true
						this.$api.put(`/units/${this.instance.id}/unitgroups/${this.newModel.id}`, this.newModel).then(({data}) => {
							this.loading = false
							console.log('updated model', data)
							this.$store.commit('UPDATE_EDIT', data)
							return this.$message.success('Model Updated!')
							// this.$store.commit('SET_PROP', {where:['unitGroups',data.id],what:data})
						}).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
					} else {
						return false;
					}
				})
            },
            trimModel(){
                this.newModel.name = this.newModel.name.trim()
                this.newModel.type = this.newModel.type.trim()
                this.newModel.code = this.newModel.code.trim()
            },
            createModel() {
                this.$refs.newModel.validate(validate => {
                    if (validate){
                       this.trimModel()

                        console.log('NEW MODEL', this.newModel)
                        this.$store.commit('SAVE_TEMP_MODEL',this.newModel)
                        this.loading = true
                        let {data:modelData} = this.$api.post(`/units/${this.instance.id}/unitgroups`, this.newModel).then(({data}) => {
                            console.log('data got back', data)
                            this.$store.commit('SET_PROP', {where:['unitGroups',data.id],what:data})
                            this.loading = false
                            this.create = true
                            this.$router.push(`/modeldetails/${data.id}`)
                        }).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})


                    }
                })

            },
            onSearchSize(searchText) {
                let units = this.$store.state.models.unitGroups
                let temp = []
                for (var i in units) {
                    temp.push(units[i].size.toString())
                }
                let unique = [...new Set(temp)];
                console.log('aaaa', unique)
                if (searchText && searchText.length > 0) {
                    this.dataSourceSize = unique.filter(x => {
                        // x = x.toLowerCase()
                        // searchText = searchText.toLowerCase()
                        return x.includes(searchText)
                    });
                }
                else {
                    return this.dataSourceSize = []
                }

            },
            onSearch(searchText) {
                let units = this.$store.state.models.unitGroups
                let temp = []
                for (var i in units) {
                    temp.push(units[i].type)
                }
                let unique = [...new Set(temp)];

                if (searchText && searchText.length > 2) {
                    this.dataSource = unique.filter(x => {
						if(x) {
							x = x.toLowerCase()
							searchText = searchText.toLowerCase()
							return x.includes(searchText)
						}
                    });
                }
                else {
                    return this.dataSource = []
                }
                console.log('uniqeee', unique)

            },
            editPageStart(){
                if (this.$route.params.id && this.$store.state.models.unitGroups[this.$route.params.id]){
					this.updateChecker = true;
                    let data = JSON.parse(JSON.stringify(this.$store.state.models.unitGroups[this.$route.params.id]))
                    this.newModel = {...this.newModel, ...data}
                }
                this.ediPage = true
            },
            addElevation(){

                this.trimModel()

                if (!this.$route.params || !this.$route.params.id) {
                    this.$refs.newModel.validate(validate => {
                        if (validate){
							if (!this.$route.params.hasOwnProperty('id')) return this.$message.error('Please save before adding elevations')
							this.$store.commit('SAVE_TEMP_MODEL',this.newModel)
							this.$store.commit('SHOW_ADD_ELEVATION')
                        }
                    })

                } else if(this.$route.params && this.$route.params.id && this.$route.params.id.trim()) {
                    this.$refs.newModel.validate(validate => {
                        if (validate){
                            this.$store.commit('SAVE_TEMP_MODEL',this.newModel)
                            this.$store.commit('SHOW_ADD_ELEVATION')


                        }
                    })

                }
                // console.log('eleee', this.newModel.ele)
                // this.$store.commit('SAVE_TEMP_MODEL',this.newModel)
                // this.$store.commit('SHOW_ADD_ELEVATION')
            },

        },

        mounted() {

        },
        beforeRouteLeave(to, from, next) {
            // console.log('to', to, from, next)
            // next()
            if (!this.delete && !this.create && !this.deleteModelType && this.change) {
                let self = this
                this.$confirm({
                    title: "Exit Page",
                    content: h => <div>Are you sure you want to exit page? All your unsaved changes will be lost.</div>,
                    okText: 'SAVE',
                    okType: 'danger',
                    cancelText: 'DISCARD',
                    centered: true,
                    async onOk() {
						self.$refs.newModel.validate(async(validate) => {
							if (validate){
								await self.submit();
								next()
							} else {
								next(false)
							}
						})
                    },
                    onCancel() {
                        next()
                    }
                })
            } else {
                this.delete = false
                this.create = false
                next()
            }
        }
        // async beforeDestroy() {
        //     await this.$store.commit('OPEN_ALERT')
        //     // alert('hiii')
        // }

    }
</script>

<style>
.no-padding .ant-popover-inner-content{
    padding:0 !important;
    min-width:150px;
}
</style>
<style scoped>
.zoomButton {
    opacity:0;
}
.previewImage:not(:hover) .zoomButton{
    opacity:0;
    transform: opacity 0.3s ease-in;
}
.previewImage:hover .zoomButton{
    opacity:1;
    transform: opacity 0.3s ease-in;
}
.testtt {
    transition: margin-top 0.3s;
}
.moreOptions {
    color:black;
}
.moreOptions:hover{
    background-color: var(--off-white-light);

}
.more-option-icon{
		position:absolute;
		display: inline-flex;
		color:black;
		height: 35px;
        width: 35px;
        /* vertical-align: middle;
		align-items: center; */
		justify-content: center;
        font-size:20px
	}
	.more-option-icon:hover{

        border-radius:35px;


		background: rgba(100, 100, 100, 0.08);

		color: var(--orange) !important;
	}
</style>

<style scoped>
.option:hover {
    background-color:#FAF9F9;
    cursor:pointer;
}
.more-option:hover .circle {
    opacity: 1;
    cursor:pointer;
}
.more-option:hover .more-icon {
    color:var(--orange);
    cursor:pointer;
}
.more-option:not(:hover) .circle {
    opacity: 0;
}
/* .image-box {
    left:50%;
    margin-left: -150px;
}
.box .image-holder-box{
    width:150px;
    height:150px;
} */
</style>
